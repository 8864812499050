import React, {lazy, Suspense,useEffect, useState} from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import theme from './Theme'
import {setAxiosHeaderAuthorization} from './api/axios_client'
import localStorage from "./store/LocalStorage"
import {useGlobalStore} from "./store/GlobalContext";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import ErrorDialog from "./components/Modal/ErrorDialog";
import {Helmet} from "react-helmet";
import GameBoxPage from "./components/GameBoxPage";
import SelectBox from "./components/GameBoxPage/SelectBox";

const SignIn = lazy( () => import('./login_page/Login') );
const ScrollableTabsButtonForce = lazy( () => import('./content_page/content') );
const RegisterStepper = lazy( () => import('./RegisterPage/RegisterStepper') );
const RedirectLogin = lazy( () => import('./login_page/RedirectLogin') );

setAxiosHeaderAuthorization(localStorage.getAccessToken())

function App() {
    const [ , dispatch] = useGlobalStore()
    const [titlePage, setTitlePage] = useState("CUSTOMER SUPPORT")
    const [descriptionPage, setDescriptionPage] = useState("FEVER SUPPORT")
    const renderLoader = () => <p>Loading</p>

    useEffect(()=>{
        //console.log("APP JS localStorage.getAllAccess() : ", localStorage.getAllAccess())
        if (process.env.REACT_APP_TITLE_INDEX !== undefined){
            setTitlePage(process.env.REACT_APP_TITLE_INDEX)
        }

        if (process.env.REACT_APP_DESCRIPTION_INDEX !== undefined){
            setDescriptionPage(process.env.REACT_APP_DESCRIPTION_INDEX)
        }

        dispatch({type: 'login_profile', payload: {data: localStorage.getAllAccess()}})
    },[])

  return (
      <>
      <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorDialog/>
            <div className="App">
                <Helmet>
                    <title>{titlePage}</title>
                    <meta name="description" content={descriptionPage} />
                    <link rel="canonical" href={`${window.location.href}`} />
                    {/* Global site tag (gtag.js) - Google Analytics  */}

                                <script async src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA}`}/>
                                <script>
                                    {`
                                      window.dataLayer = window.dataLayer || [];
                                      function gtag(){dataLayer.push(arguments);}
                                      gtag('js', new Date());
                                      gtag('config', "${process.env.REACT_APP_GA}");
                                    `}
                                </script>
                </Helmet>
                <Router>
                    <Suspense fallback={renderLoader()}>
                        <Switch>
                            {
                                (process.env.REACT_APP_LUCKYGAME_ENABLE === "enable")&&(
                                    <Route path="/selected_card">
                                        <SelectBox />
                                    </Route>
                                )
                            }
                            <Route path="/redirect-login">
                                <RedirectLogin />
                            </Route>
                            <Route path="/content">
                                <ScrollableTabsButtonForce />
                            </Route>
                            <Route path="/register">
                                <RegisterStepper />
                            </Route>
                            <Route path="/">
                                <SignIn />
                            </Route>
                        </Switch>
                    </Suspense>
                </Router>
            </div>
      </ThemeProvider>
      </>
  );
}

export default App;
