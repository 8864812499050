import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalProvider } from './store/GlobalContext'
import ReactHtmlParser, {
  processNodes
} from "react-html-parser";

const { Provider, Consumer } = React.createContext();

function transform(node, index) {
  if (node.type === "tag" && node.name === "label" && node.attribs.onclick === "menuToggle()") {
    return (
      <Consumer>
        {menuToggle => (
          <label onClick={menuToggle} key={index} class={node.attribs.class}>
            {processNodes(node.children, transform)}
          </label>
        )}
      </Consumer>
    );
  }
}
const options = {
  decodeEntities: true,
  transform
};

const menuToggle = () => {
  let menu = document.getElementById( "nav-menu" );
  if ( menu.style.display === "grid" ) {
    menu.style.display = "none";
  } else {
    menu.style.display = "grid";
  }
};

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <Provider value={menuToggle}>
        {
          process.env.REACT_APP_HEADER ?
            <>{ReactHtmlParser( process.env.REACT_APP_HEADER , options )}</> :
            <header/>
        }
      </Provider>
      <App/>
      {
        process.env.REACT_APP_FOOTER ?
          <>{ReactHtmlParser( process.env.REACT_APP_FOOTER )}</> :
          <footer/>
      }
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById( 'root' )
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
