import React, {useEffect, useLayoutEffect, useState} from "react";
import Button from '@material-ui/core/Button';
import './gamebox.css';
import {useHistory} from "react-router-dom";


export default function ResultGameBox (props) {
    let history = useHistory()
    const {message, luckyAmount} = props
    const [congratsImage, setCongratsImage] = useState("https://d22eqwgf5ils9u.cloudfront.net/demo/congrats_lucky_banner2.png")
    const [overImage, setOverImage] = useState("https://d22eqwgf5ils9u.cloudfront.net/demo/failed_lucky_banner.png")

    const [congratsCss, setCongratsCss] = useState("bonus-text")
    const [noticeCss, setNoticeCss] = useState("")



    const backHomeScreen = ()=>{
        history.push("/content")
    }

    useEffect(()=>{
        const swapCongratsCss =  setInterval(() =>{
            if (congratsCss === "bonus-text"){
                setCongratsCss("bonus-text-swap")
            }else{
                setCongratsCss("bonus-text")
            }
        }, 1500)

        return () => clearInterval(swapCongratsCss)

    },[congratsCss])

    useEffect(()=>{
        const swapNoticeCss =  setInterval(() =>{
            if (noticeCss === ""){
                setNoticeCss("notice-text-swap")
            }else{
                setNoticeCss("")
            }
        }, 1000)

        return () => clearInterval(swapNoticeCss)

    },[noticeCss])

    useLayoutEffect(()=>{
        if (process.env.REACT_APP_LUCKY_GAME_SUCCESS_IMAGE !== ""){
            setCongratsImage(process.env.REACT_APP_LUCKY_GAME_SUCCESS_IMAGE)
        }
    },[])

    return(
            <div className={"vertical-align-center"}>
                {/*{message}*/}
                {
                    (message === "success")?(
                        // <img src={"https://d22eqwgf5ils9u.cloudfront.net/demo/got_lucky_banner.png"} width={"300px"}/>
                        <>
                            <img src={congratsImage} width={"400px"}/>
                            <div className={"vertical-align-center"}>
                                <h1 className={congratsCss}> {luckyAmount} บาท</h1>
                                <h6 className={noticeCss}> *หมายเหตุ เครดิตโบนัสจะเข้าสู่ยูสเซอร์ของลูกค้าโดยอัตโนมัติคะ</h6>
                            </div>
                            <div className={"button-padding"}>
                                <Button variant={"contained"} color={"secondary"} onClick={()=>backHomeScreen()}>
                                    กลับสู่เมนูหลัก
                                </Button>
                            </div>
                        </>

                    ):(
                        <>
                        <img src={overImage} width={"500px"}/>
                    <div className={"button-padding"}>
                    <Button variant={"contained"} color={"secondary"} onClick={()=>backHomeScreen()}>
                    กลับสู่เมนูหลัก
                    </Button>
                    </div>
                        </>
                    )
                }
            </div>
    )

}